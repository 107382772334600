/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, HostListener, OnDestroy, OnInit, ViewChild, KeyValueDiffer, KeyValueDiffers } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, BehaviorSubject, firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import moment from "moment";
import * as _ from "lodash";

import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Constants } from "src/app/constants/constants";
import { Tag, UserPermissions, ZixiObject } from "src/app/models/shared";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "src/app/services/title.service";
import { UsersService } from "../../account-management/users/users.service";
import { ChannelsService } from "../../channels/channels.service";
//
import { Incident } from "../incident";
import { IncidentsService } from "../incidents.service";
//
import { SourcesService } from "../../sources/sources.service";
import { TargetApiType } from "../../channels/channel";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { MediaConnectSourcesService } from "../../sources/mediaconnect-sources.service";
import { TargetsService } from "../../targets/targets.service";
import { ZecsService } from "../../zecs/zecs.service";
import { ModalService } from "src/app/components/shared/modals/modal.service";

import { FlexGraphsService } from "src/app/services/flex-graphs.service";

import { ErrorService } from "src/app/components/error/error.service";
import { NetworkPipe } from "src/app/pipes/network.pipe";
import { TourService } from "ngx-ui-tour-md-menu";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { TourSteps } from "src/app/constants/tour-steps";

type EventsObjects = ZixiObject | ZixiObject[] | number[];

type EventsTypedObjects = {
    feeder?: EventsObjects;
    receiver?: EventsObjects;
    broadcaster?: EventsObjects;
    source?: EventsObjects;
    mediaconnect_sources?: EventsObjects;
    //
    adaptive_channel?: EventsObjects;
    delivery_channel?: EventsObjects;
    mediaconnect_flows?: EventsObjects;
    medialive_channels?: EventsObjects;
    failover_channel?: EventsObjects;
    //
    publishing_target?: EventsObjects;
    zixi_pull?: EventsObjects;
    zixi_push?: EventsObjects;
    rtmp_push?: EventsObjects;
    udp_rtp?: EventsObjects;
    rist?: EventsObjects;
    srt_targets?: EventsObjects;
    ndi_targets?: EventsObjects;
    mediaconnect_cdi_targets?: EventsObjects;
    mediaconnect_jpegxs_targets?: EventsObjects;
    medialive_http_targets?: EventsObjects;
    //
    incidents: number[];
};

type IncidentObject = {
    type: string;
    id: number;
    object;
    name?: string;
    tlo: boolean;
};

interface ObjectNode {
    id?: number;
    name: string;
    type?: string;
    object?: IncidentObject;
    children?: ObjectNode[];
    level: number;
    expandable?: boolean;
    // graph
    objectId?: number;
    objectType?: string;
    subType?: string;
    panels?: string[];
}

interface flatNode {
    id?: number;
    name: string;
    type?: string;
    level: number;
    expandable: boolean;
    object?: IncidentObject;
    objectId?: number;
    objectType?: string;
    panels?: string[];
    subType?: string;
}

import { grafanaFlexEncoder as FlexDashboardEncoder } from "@zixi/shared-utils";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { NetworksService } from "../../networks/networks.service";
import { IncidentDialogComponent } from "../incident-dialog/incident-dialog.component";

@Component({
    selector: "app-incident",
    templateUrl: "./incident.component.html",
    styleUrls: ["./incident.component.scss"],
    providers: [NetworkPipe]
})
export class IncidentComponent implements OnInit, OnDestroy {
    incident: Incident;
    existingIncident: Incident;
    incidentID: number;
    loadingDetails = true;

    resourceTags: Tag[];
    userPermissions: UserPermissions;

    object = null;
    objectType: string;
    objectSubtype: string;

    selectedObjectsEventsObjects: EventsTypedObjects = { incidents: [] };
    tableObjects: IncidentObject[] = [];

    showDatePicker = false;
    showFrom = true;
    pickerCounter = 0;
    toDate: moment.Moment;
    fromDate: moment.Moment;
    lastFromDate: moment.Moment;
    lastToDate: moment.Moment;
    toDateString: string;
    fromDateString: string;
    dateRange: string = null;
    datePresets = ["LAST_1_HOUR", "LAST_6_HOURS", "LAST_12_HOURS", "LAST_24_HOURS", "LAST_48_HOURS", "LAST_7_DAYS"];
    dateFormat = "MM/DD/YYYY HH:mm:ss";

    viewOption: "graphs" | "events" = "graphs";
    graphColumns = "2";

    saving = false;
    submitted = false;
    constants = Constants;

    datesChanged = false;
    unsavedChanges = false;

    uniqueNetworkCount = 0;
    uniqueBroadcasterCount = 0;
    legend = false;
    nodesExpanded = false;
    treeHeaderCheck: boolean;
    selectedCount = 0;
    showNotes = false;
    showGraphList = true;
    loadingGraphs = true;
    graphsChanged = false;
    noObjects = false;
    graphLink: string;
    graphPanelConfig = [];
    existingGraphPanelConfig = [];

    darkMode: boolean;
    darkModeSubscription: Subscription;

    differ: KeyValueDiffer<string, any>;
    private tourSteps = TourSteps.incidentFeatures;

    // @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener("window:beforeunload")
    private browserDialog() {
        if (this.unsavedChanges) {
            return false;
        } else return true;
    }

    @ViewChild("treetooltip") public tooltip: NgbTooltip;

    private tableObjectsBS$ = new BehaviorSubject<{ type: string; object }[]>([]);
    private incidentsSubscription: Subscription;
    private toDateSubscription: Subscription;
    private fromDateSubscription: Subscription;
    private tourSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private is: IncidentsService,
        public sharedService: SharedService,
        private userService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService,
        private channelsService: ChannelsService,
        private sourcesService: SourcesService,
        private zecsService: ZecsService,
        private broadcastersService: BroadcastersService,
        private targetsService: TargetsService,
        private mediaConnectSourcesService: MediaConnectSourcesService,
        private ngbModal: NgbModal,
        private errorService: ErrorService,
        private modalService: ModalService,
        private networkPipe: NetworkPipe,
        public tourService: TourService,
        private fgs: FlexGraphsService,
        private differs: KeyValueDiffers,
        public mixpanelService: MixpanelService,
        private navigationService: NavigationService,
        private netService: NetworksService
    ) {
        this.darkModeSubscription = this.navigationService.isDarkMode.subscribe(b => {
            if (this.darkMode !== b) {
                this.darkMode = b;
                this.reloadGraphs();
            }
        });

        this.route.paramMap.subscribe(params => {
            this.incidentID = parseInt(params.get("id"), 10);
            if (this.incidentID) this.incident = this.is.getCachedIncident(this.incidentID);

            // Set Title
            this.titleService.setTitle("INCIDENT", "", this.incident);

            // Loaded Details?
            if (!this.incident?.hasFullDetails) {
                this.loadingDetails = true;
                this.is.refreshIncident(this.incidentID, true);
            }

            //
            this.differ = this.differs.find({}).create();
        });
    }

    back() {
        this.router.navigate([Constants.urls.incidents]);
    }
    canDeactivate() {
        return true;
    }

    columnsChange() {
        localStorage.setItem("incidentColumns", this.graphColumns);
        this.reloadGraphs();
    }

    legendChange() {
        localStorage.setItem("incidentLegend", this.legend.toString());
        this.reloadGraphs();
    }

    async notes(incident) {
        this.showNotes = !this.showNotes;
        const modal = this.ngbModal.open(IncidentDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.incident = incident;

        await modal.result;
        return modal.result;
    }

    toggleGraphList() {
        this.showGraphList = !this.showGraphList;
    }

    print() {
        setTimeout(() => {
            window.print();
        }, 0);
        this.mixpanelService.sendEvent("export incident", {
            id: this.incident.id
        });
    }

    canEditIncident() {
        return (
            this.incident?.is_public ||
            this.userPermissions?.is_admin ||
            this.userPermissions?.is_objects_manager ||
            this.userPermissions?.is_zixi_support_write ||
            this.userPermissions?.is_zixi_admin ||
            this.userPermissions?.is_incident_manager
        );
    }

    async ngOnInit() {
        // Localstorage
        this.graphColumns = localStorage.getItem("incidentColumns") ?? "2";
        this.legend = localStorage.getItem("incidentLegend") === "true" ? true : false;

        // Sub
        this.incidentsSubscription = this.is.incidents.subscribe(async incidents => {
            this.incident = incidents.find((i: Incident) => i.id === this.incidentID);
            if (this.incident && this.incident.hasFullDetails && this.loadingDetails) {
                this.titleService.setTitle("INCIDENT", "", this.incident);
                // Clone
                if (!this.existingIncident) this.existingIncident = _.cloneDeep(this.incident);
                // Open
                await this.openIncident(this.incident);
                this.loadingDetails = false;
            }
        });

        // Resource tags
        this.sharedService
            .getResourceTagsByType("incidents")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // Dates
        this.setDateRangeOnLoad();

        this.toDateSubscription = this.is.getToDate.pipe().subscribe(d => {
            if (d) {
                this.toDate = d;
                this.lastToDate = d;
                this.setDateRangeFromChild();
            }
        });

        this.fromDateSubscription = this.is.getFromDate.pipe().subscribe(d => {
            if (d) {
                this.fromDate = d;
                this.lastFromDate = d;
                this.setDateRangeFromChild();
            }
        });

        // Feature Guide
        this.tourService.initialize(this.tourSteps);
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "sixthIncidentAnchor") this.viewOption = "graphs";
            if (step.step.anchorId === "eigthIncidentAnchor") this.showGraphList = true;
            if (step.step.anchorId === "ninthIncidentAnchor") this.tooltip.open();
            if (step.step.anchorId === "tenthIncidentAnchor") this.expandFirstNode();
            if (step.step.anchorId === "eleventhIncidentAnchor") this.viewOption = "graphs";
            if (step.step.anchorId === "twelfthIncidentAnchor") this.viewOption = "events";
            if (step.step.anchorId === "fourteenthIncidentAnchor") this.showNotes = true;
        });
    }

    ngOnDestroy() {
        this.is.setFromDate(null);
        this.is.setToDate(null);
        this.is.setPickerFromDate(null);
        this.is.setPickerToDate(null);
        //
        this.incidentsSubscription.unsubscribe();
        this.toDateSubscription.unsubscribe();
        this.fromDateSubscription.unsubscribe();
        this.tourSubscription.unsubscribe();
        if (this.darkModeSubscription) this.darkModeSubscription?.unsubscribe();
    }

    getNetworkName(item: { type: string; object }) {
        return item.type === "target"
            ? this.networkPipe.transform(item.object.target.location)
            : this.networkPipe.transform(item.object.location);
    }

    getUniqueNetworkCount() {
        if (this.tableObjects?.length) {
            const networkArray = this.tableObjects.map(row =>
                row.type === "target"
                    ? this.networkPipe.transform(row.object.target.location)
                    : this.networkPipe.transform(row.object.location)
            );
            const uniqueNetworkSet = new Set(networkArray);
            const uniqueNetworkArray = Array.from(uniqueNetworkSet);
            this.uniqueNetworkCount = uniqueNetworkArray.length - 1;
        }
    }

    getUniqueBroadcasterCount() {
        if (this.tableObjects?.length) {
            const broadcasterNamesArrays = this.tableObjects.map(row =>
                this.getActiveBroadcasters(row).map(o => o.name)
            );

            const flattenedArray = [].concat(...broadcasterNamesArrays);
            const uniqueNamesSet = new Set(flattenedArray);
            const uniqueNamesArray = Array.from(uniqueNamesSet);

            this.uniqueBroadcasterCount = uniqueNamesArray.length;
        }
    }

    get tableObjects$() {
        return this.tableObjectsBS$.asObservable();
    }

    public prepTableData(init: boolean) {
        if (this.tableObjects) {
            // For table
            this.tableObjects = [...this.tableObjects];
            this.tableObjectsBS$.next(this.tableObjects);

            // For info bar
            this.getUniqueNetworkCount();
            this.getUniqueBroadcasterCount();

            // For tree
            const treeObjects = [];
            for (const obj of this.tableObjects) {
                if (obj.type === "target") {
                    treeObjects.push({
                        id: obj.object.target.id,
                        name: obj.object.target.name,
                        object: obj.object,
                        children: this.getGraphOptions(obj.type, obj.object, obj.tlo),
                        type: obj.type
                    });
                } else {
                    treeObjects.push({
                        id: obj.object.id,
                        name: obj.object.name,
                        object: obj.object,
                        children: this.getGraphOptions(obj.type, obj.object, obj.tlo),
                        type: obj.type
                    });
                }
            }

            this.dataSource.data = [...treeObjects];

            if (!this.dataSource.data.length) this.noObjects = true;
            else this.noObjects = false;
        }

        // initial graph selection
        if (init && !this.noObjects) {
            // new code
            // check if new graph def is saved to config
            if (this.incident.configuration.flexGraphs && this.incident.configuration.flexGraphs.length) {
                for (const graph of this.incident.configuration.flexGraphs) {
                    for (const id of graph.objectIds) {
                        const node = this.findTreeNodeByFlexPanel(id, graph.objectType, graph.panel);
                        if (!this.checklistSelection.isSelected(node)) {
                            this.leafItemSelectionToggle(node);
                        }
                    }
                }
            }
            // legacy code included to support old incidents
            // if not try to use old def for selected objects
            else {
                // select selected objects from selectedObjectIDs
                if (
                    this.incident.configuration?.selectedObjectIDs &&
                    this.incident.configuration?.selectedObjectIDs.length
                ) {
                    for (const id of this.incident.configuration?.selectedObjectIDs) {
                        const node = this.findTreeNodeByID(id);
                        if (node) this.itemSelectionToggle(node);
                    }
                } else {
                    // if no selectedObjectIDs using trigger object
                    if (this.incident.triggering_object_type && this.incident.triggering_object_id) {
                        const id = this.getObjectIDFromTypeAndID(
                            this.incident.triggering_object_type,
                            this.incident.triggering_object_id
                        );
                        const node = this.findTreeNodeByID(id);
                        if (node) this.itemSelectionToggle(node);
                    } else {
                        // if no trigger object take first in object list
                        if (this.incident.objects) {
                            for (const o of this.incident.objects) {
                                if (this.incident.objects[0] === o) {
                                    const id = this.getObjectIDFromTypeAndID(o.object_type, o.object_id);
                                    const node = this.findTreeNodeByID(id);
                                    if (node) this.itemSelectionToggle(node);
                                }
                            }
                        }
                    }
                }
            }

            this.existingGraphPanelConfig = [...this.graphPanelConfig];
            this.hasIncidentChanged();
        }
    }

    hasIncidentChanged() {
        this.haveDatesChanged();
        if (
            !this.incident ||
            !this.existingIncident ||
            this.incident === undefined ||
            this.existingIncident === undefined
        ) {
            this.unsavedChanges = false;
            return;
        }

        const incidentEqual =
            _.isEqual(this.incident.name, this.existingIncident.name) &&
            _.isEqual(this.incident.state, this.existingIncident.state);
        const graphsEqual = _.isEqual(this.graphPanelConfig, this.existingGraphPanelConfig);

        if (incidentEqual && graphsEqual && !this.datesChanged) {
            this.unsavedChanges = false;
        } else {
            this.unsavedChanges = true;
        }
    }

    cancelIncidentChanges() {
        this.mixpanelService.sendEvent("cancel incident changes", {
            id: this.incident.id
        });
        this.resetDate();
        this.resetGraphs();
        this.resetIncident();
    }

    resetIncident() {
        this.incident = _.cloneDeep(this.existingIncident);
    }

    resetGraphs() {
        // Deselect all
        for (const node of this.treeControl.dataNodes) {
            this.checklistSelection.deselect(node);
            this.checkAllParentsSelection(node);
        }
        // select all from existing panel config
        for (const graph of this.existingGraphPanelConfig) {
            for (const id of graph.objectIds) {
                const node = this.findTreeNodeByFlexPanel(id, graph.objectType, graph.panel);
                if (!this.checklistSelection.isSelected(node)) {
                    this.leafItemSelectionToggle(node);
                }
            }
        }
    }

    viewChange() {
        this.mixpanelService.sendEvent("change incident view to " + this.viewOption, {
            id: this.incident.id
        });
    }

    async editIncident() {
        const result = await this.modalService.editIncident(this.incident);
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.incident.name,
            likely_cause: this.incident.likely_cause,
            state: this.incident.state,
            start_time: this.datesChanged ? this.fromDate : this.incident.start_time,
            end_time: this.datesChanged ? this.toDate : this.incident.end_time,
            configuration: this.incident.configuration
        };

        // flexGraphs
        model.configuration.flexGraphs = this.graphPanelConfig;

        const result = await this.is.updateIncident(this.incident.id, model);
        if (result) {
            this.incident = Object.assign({}, result);
            this.existingIncident = Object.assign({}, result);
            this.errorService.setLastError(null);
            this.resetDate();
            this.existingGraphPanelConfig = [...this.graphPanelConfig];

            this.mixpanelService.sendEvent("save incident changes", {
                id: this.incident.id
            });

            this.saving = false;
        } else this.saving = false;

        this.saving = false;
    }

    getObjectIDFromTypeAndID(type: string, id: number) {
        let objectID;
        if (type === "source") objectID = "s-" + id;
        if (type === "mediaconnect_sources") objectID = "mc-" + id;
        if (type === "feeder") objectID = "f-" + id;
        if (type === "zec") objectID = "z-" + id;
        if (type === "receiver") objectID = "r-" + id;
        if (type === "broadcaster") objectID = "b-" + id;
        if (type === "adaptive_channel") objectID = "c-" + id + "-ac";
        if (type === "delivery_channel") objectID = "c-" + id + "-dc";
        if (type === "failover_channel") objectID = "c-" + id + "-fc";
        if (type === "mediaconnect_flows") objectID = "c-" + id + "-mcc";
        if (type === "medialive_channels") objectID = "c-" + id + "-ml";
        // Targets
        if (type === "http" || type === "publishing_target") objectID = "t-" + id + "-http";
        if (type === "push" || type === "zixi_push") objectID = "t-" + id + "-push";
        if (type === "pull" || type === "zixi_pull") objectID = "t-" + id + "-pull";
        if (type === "rtmp" || type === "rtmp_push") objectID = "t-" + id + "-rtmp";
        if (type === "udp_rtp") objectID = "t-" + id + "-udp_rtp";
        if (type === "srt" || type === "srt_targets") objectID = "t-" + id + "-srt";
        if (type === "ndi" || type === "ndi_targets") objectID = "t-" + id + "-ndi";
        if (type === "rist") objectID = "t-" + id + "-rist";
        if (type === "medialive_http" || type === "medialive_http_targets") objectID = "t-" + id + "-medialive_http";
        if (type === "cdi" || type === "mediaconnect_cdi_targets") objectID = "t-" + id + "-cdi";
        if (type === "jpegxs" || type === "mediaconnect_jpegxs_targets") objectID = "t-" + id + "-jpegxs";
        if (type === "networks") objectID = "net-" + id;

        if (!type && !id) objectID = "unknown-x"; // unknown object, due to insufficient permissions
        //
        return objectID;
    }

    findTreeNodeByFlexPanel(id: number, objectType: string, panel: string) {
        for (const node of this.treeControl.dataNodes) {
            if (node.level && node.objectId === id && node.objectType === objectType && node.panels.includes(panel)) {
                return node;
            }
        }
    }

    findTreeNodeByID(id: string) {
        const fields = id.split("-");
        const type = fields[0];
        const n = parseInt(fields[1], 10);
        const subtype = fields[2];
        const typeObject = this.handleObjectType(type, subtype);
        for (const node of this.treeControl.dataNodes) {
            if (typeObject.targetType) {
                if (
                    !node.level &&
                    node.id === n &&
                    node.type === typeObject.objectType &&
                    node.object.type === typeObject.targetType
                ) {
                    return node;
                }
            } else {
                if (!node.level && node.id === n && node.type === typeObject.objectType) {
                    return node;
                }
            }
        }
    }

    isFirstNode(node: flatNode) {
        const n = this.treeControl.dataNodes.find(n => n.expandable);
        if (n.id === node.id && n.type === node.type && n.name === node.name) return true;
        else return false;
    }

    isFirstChildNode(node: flatNode) {
        const n = this.treeControl.dataNodes.find(n => !n.expandable && n.level);
        if (n && n.objectId === node.objectId && n.objectType === node.objectType && n.name === node.name) return true;
        return false;
    }

    expandFirstNode() {
        const n = this.treeControl.dataNodes.find(n => n.expandable);
        const expanded = this.treeControl.isExpanded(n);
        if (!expanded) this.treeControl.expand(n);
    }

    handleObjectType(type, subtype): { objectType: string; targetType: string } {
        let objectType;
        let targetType = null;
        if (type === "f") objectType = "feeder";
        else if (type === "z") objectType = "zec";
        else if (type === "r") objectType = "receiver";
        else if (type === "b") objectType = "broadcaster";
        else if (type === "s") objectType = "source";
        else if (type === "mc") objectType = "mediaconnect_sources";
        else if (type === "c") {
            if (subtype === "mcc") objectType = "mediaconnect_flows";
            else if (subtype === "ac") objectType = "adaptive_channel";
            else if (subtype === "dc") objectType = "delivery_channel";
            else if (subtype === "ml") objectType = "medialive_channels";
            else if (subtype === "fc") objectType = "failover_channel";
        } else if (type === "t") {
            objectType = "target";
            targetType = subtype;
        }
        return { objectType, targetType };
    }

    findTableObject(id, type, subtype) {
        let objectType;
        let targetType;
        const r = this.handleObjectType(type, subtype);
        objectType = r.objectType;
        targetType = r.targetType;
        if (targetType && type === "t") {
            if (
                this.tableObjects.find(
                    i => i.type === objectType && i.object.objId === id && i.object.apiType === targetType
                )
            )
                return this.tableObjects.find(
                    i => i.type === objectType && i.object.objId === id && i.object.apiType === targetType
                );
            else return false;
        } else {
            if (this.tableObjects.find(i => i.type === objectType && i.object.id === id))
                return this.tableObjects.find(i => i.type === objectType && i.object.id === id);
            else return false;
        }
    }

    addEventsObjects(type: string, object) {
        if (this.selectedObjectsEventsObjects[type]) this.selectedObjectsEventsObjects[type].push(object);
        else this.selectedObjectsEventsObjects[type] = [object];
    }

    clearEventsObjects() {
        this.selectedObjectsEventsObjects = { incidents: [this.incidentID] };
    }

    async selectedObjectsChanged() {
        // Events Objects for all objects list
        this.clearEventsObjects();
        for (const o of this.tableObjects) {
            if (o.type !== "target") this.addEventsObjects(o.type, o.object);
            else {
                if (o.object.pull) this.addEventsObjects("zixi_pull", o.object.target);
                if (o.object.push) this.addEventsObjects("zixi_push", o.object.target);
                if (o.object.rtmp) this.addEventsObjects("rtmp_push", o.object.target);
                if (o.object.adaptive) this.addEventsObjects("publishing_target", o.object.target);
                if (o.object.udp_rtp) this.addEventsObjects("udp_rtp", o.object.target);
                if (o.object.rist) this.addEventsObjects("rist", o.object.target);
                if (o.object.srt) this.addEventsObjects("srt_targets", o.object.target);
                if (o.object.ndi) this.addEventsObjects("ndi_targets", o.object.target);
                if (o.object.medialive_http) this.addEventsObjects("medialive_http_targets", o.object.target);
                if (o.object.cdi) this.addEventsObjects("mediaconnect_cdi_targets", o.object.target);
                if (o.object.jpegxs) this.addEventsObjects("mediaconnect_jpegxs_targets", o.object.target);
            }
        }
        this.selectedObjectsEventsObjects = { ...this.selectedObjectsEventsObjects, incidents: [this.incidentID] };
    }

    getActiveBroadcasters(item: { type: string; object }) {
        //
        let activeBroadcasters = [];
        if (item.type === "source") {
            if (item.object.status && item.object.status.active_broadcasters)
                activeBroadcasters = item.object.status.active_broadcasters;
            else if (item.object.status && item.object.status.active_broadcaster)
                activeBroadcasters = [item.object.status.active_broadcaster];
        } else if (item.type === "target") {
            if (item.object.target.status && item.object.target.status.active_broadcasters)
                activeBroadcasters = item.object.target.status.active_broadcasters;
            else if (item.object.target.status && item.object.target.status.active_broadcaster)
                activeBroadcasters = [item.object.target.status.active_broadcaster];
            else if (item.object.target.broadcaster) activeBroadcasters = [item.object.target.broadcaster];
        } else if (
            item.type ===
            ("mediaconnect_flows" ||
                "adaptive_channel" ||
                "delivery_channel" ||
                "failover_channel" ||
                "medialive_channels")
        ) {
            if (item.object.status && item.object.status.active_broadcasters) {
                activeBroadcasters = item.object.status.active_broadcasters;
            } else if (item.object.status && item.object.status.active_broadcaster) {
                activeBroadcasters = [item.object.status.active_broadcaster];
            }
            activeBroadcasters = _.map(activeBroadcasters, activeBroadcaster => {
                return Object.assign(
                    {},
                    activeBroadcaster,
                    _.find(item.object.processingCluster.broadcasters, { id: activeBroadcaster.id })
                );
            });
        } else {
            if (item.object.status && item.object.status.active_broadcasters)
                activeBroadcasters = item.object.status.active_broadcasters;
            else if (item.object.status && item.object.status.active_broadcaster)
                activeBroadcasters = [item.object.status.active_broadcaster];
        }
        //
        return activeBroadcasters;
    }

    getActiveBroadcasterNames(item: { type: string; object }) {
        return this.getActiveBroadcasters(item)
            .map(t => t.name)
            .join(", ");
    }

    async addNewObjectToTable(id: string, tlo: boolean) {
        const fields = id.split("-");
        const type = fields[0];
        const n = parseInt(fields[1], 10);
        const subtype = fields[2];
        //
        if (type === "f") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.zecsService.refreshZec(n, "FEEDER", false).toPromise();
                const feeder = this.zecsService.getCachedZec("FEEDER", null, n);
                if (feeder) this.tableObjects.push({ type: "feeder", id: feeder.id, object: feeder, tlo });
            }
        } else if (type === "z") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.zecsService.refreshZec(n, "ZEC", false).toPromise();
                const zec = this.zecsService.getCachedZec("ZEC", null, n);
                if (zec) this.tableObjects.push({ type: "zec", id: zec.id, object: zec, tlo });
            }
        } else if (type === "r") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.zecsService.refreshZec(n, "RECEIVER", false).toPromise();
                const receiver = this.zecsService.getCachedZec("RECEIVER", null, n);
                if (receiver) this.tableObjects.push({ type: "receiver", id: receiver.id, object: receiver, tlo });
            }
        } else if (type === "b") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.broadcastersService.refreshBroadcaster(n, false).toPromise();
                const broadcaster = this.broadcastersService.getCachedBroadcaster(n);
                if (broadcaster)
                    this.tableObjects.push({ type: "broadcaster", id: broadcaster.id, object: broadcaster, tlo });
            }
        } else if (type === "s") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.sourcesService.refreshSource(n, false).toPromise();
                const source = this.sourcesService.getCachedSource(null, null, n);
                if (source) this.tableObjects.push({ type: "source", id: source.id, object: source, tlo });
            }
        } else if (type === "mc") {
            if (!this.findTableObject(n, type, subtype)) {
                const MCsource = await this.mediaConnectSourcesService.refreshMediaConnectSource(n, false).toPromise();
                if (MCsource)
                    this.tableObjects.push({ type: "mediaconnect_sources", id: MCsource.id, object: MCsource, tlo });
            }
        } else if (type === "c") {
            if (subtype === "mcc") {
                if (!this.findTableObject(n, type, subtype)) {
                    const channel = await this.channelsService.getMediaConnectFlow(n);
                    if (channel)
                        this.tableObjects.push({ type: "mediaconnect_flows", id: channel.id, object: channel, tlo });
                }
            } else if (subtype === "ac") {
                if (!this.findTableObject(n, type, subtype)) {
                    const channel = await this.channelsService.getAdaptiveChannel(n);
                    if (channel)
                        this.tableObjects.push({ type: "adaptive_channel", id: channel.id, object: channel, tlo });
                }
            } else if (subtype === "dc") {
                if (!this.findTableObject(n, type, subtype)) {
                    const channel = await this.channelsService.getDeliveryChannel(n);
                    if (channel)
                        this.tableObjects.push({ type: "delivery_channel", id: channel.id, object: channel, tlo });
                }
            } else if (subtype === "fc") {
                if (!this.findTableObject(n, type, subtype)) {
                    const channel = await this.channelsService.getFailoverChannel(n);
                    if (channel)
                        this.tableObjects.push({ type: "failover_channel", id: channel.id, object: channel, tlo });
                }
            } else if (subtype === "ml") {
                if (!this.findTableObject(n, type, subtype)) {
                    const channel = await this.channelsService.getMediaLiveChannel(n);
                    if (channel)
                        this.tableObjects.push({ type: "medialive_channels", id: channel.id, object: channel, tlo });
                }
            }
        } else if (type === "t") {
            if (!this.findTableObject(n, type, subtype)) {
                await this.targetsService.refreshTarget(subtype, n, false).toPromise();
                const target = this.targetsService.getCachedTarget(n, subtype);
                if (target) this.tableObjects.push({ type: "target", id: target.target.id, object: target, tlo });
            }
        } else if (type === "net") {
            if (!this.findTableObject(n, type, subtype)) {
                await firstValueFrom(this.netService.refreshNetwork(n, false));
                const net = this.netService.getCachedNetwork(n);
                if (net) this.tableObjects.push({ type: "network", id: net.id, object: net, tlo });
            }
        } else if (type === "unknown") {
            if (!this.findTableObject(n, type, subtype)) {
                this.tableObjects.push({ type: "unknown", id: null, object: {}, tlo });
            }
        }
        return;
    }

    async openIncident(incident: Incident) {
        // console.log("openIncident", incident);
        //
        // add trigger object
        if (incident.triggering_object_type && incident.triggering_object_id) {
            await this.addNewObjectToTable(
                this.getObjectIDFromTypeAndID(incident.triggering_object_type, incident.triggering_object_id),
                true
            );
        }
        // add incident objects
        if (incident.objects) {
            for (const o of incident.objects) {
                await this.addNewObjectToTable(this.getObjectIDFromTypeAndID(o.object_type, o.object_id), false);
            }
        }
        // legacy code
        // add selectedObjects
        if (incident.configuration?.selectedObjectIDs && incident.configuration?.selectedObjectIDs.length) {
            for (const id of incident.configuration?.selectedObjectIDs || []) {
                await this.addNewObjectToTable(id, false);
            }
        }
        //
        this.prepTableData(true);
        this.selectedObjectsChanged();
        return;
    }

    /* Datepicker Stuff */
    setDateRangeFromPreset(preset?: string) {
        this.toDate = moment();
        this.lastToDate = moment();
        this.toDateString = moment().format();
        //
        if (!preset) {
            this.dateRange = this.translate.instant("LAST_12_HOURS");
            this.fromDate = moment().subtract(12, "hours");
            this.lastFromDate = moment().subtract(12, "hours");
            this.fromDateString = moment().subtract(12, "hours").format();
        } else {
            this.dateRange = this.translate.instant(preset);
            if (preset === "LAST_1_HOUR") {
                this.fromDate = moment().subtract(1, "hours");
                this.lastFromDate = moment().subtract(1, "hours");
                this.fromDateString = moment().subtract(1, "hours").format();
            }
            if (preset === "LAST_6_HOURS") {
                this.fromDate = moment().subtract(6, "hours");
                this.lastFromDate = moment().subtract(6, "hours");
                this.fromDateString = moment().subtract(6, "hours").format();
            }
            if (preset === "LAST_12_HOURS") {
                this.fromDate = moment().subtract(12, "hours");
                this.lastFromDate = moment().subtract(12, "hours");
                this.fromDateString = moment().subtract(12, "hours").format();
            }
            if (preset === "LAST_24_HOURS") {
                this.fromDate = moment().subtract(24, "hours");
                this.lastFromDate = moment().subtract(24, "hours");
                this.fromDateString = moment().subtract(24, "hours").format();
            }
            if (preset === "LAST_48_HOURS") {
                this.fromDate = moment().subtract(48, "hours");
                this.lastFromDate = moment().subtract(48, "hours");
                this.fromDateString = moment().subtract(48, "hours").format();
            }
            if (preset === "LAST_7_DAYS") {
                this.fromDate = moment().subtract(7, "days");
                this.lastFromDate = moment().subtract(7, "days");
                this.fromDateString = moment().subtract(7, "days").format();
            }
            //
            this.is.setPickerFromDate(this.fromDate);
            this.is.setPickerToDate(this.toDate);
        }
        this.hasIncidentChanged();
    }

    setDateRangeFromPicker() {
        const from: string = moment(this.fromDateString).format(this.dateFormat);
        const to: string = moment(this.toDateString).format(this.dateFormat);

        this.fromDate = moment(this.fromDateString);
        this.toDate = moment(this.toDateString);
        this.lastFromDate = moment(this.fromDateString);
        this.lastToDate = moment(this.toDateString);

        this.is.setPickerFromDate(moment(this.fromDateString));
        this.is.setPickerToDate(moment(this.toDateString));
        this.dateRange = from + " - " + to;
        this.hasIncidentChanged();
    }

    setDateRangeOnLoad() {
        if (this.incident && this.incident._frontData.start_time && this.incident._frontData.end_time) {
            const from: string = moment(this.incident._frontData.start_time).format(this.dateFormat);
            const to: string = moment(this.incident._frontData.end_time).format(this.dateFormat);

            this.fromDate = moment(this.incident._frontData.start_time);
            this.lastFromDate = moment(this.incident._frontData.start_time);
            this.fromDateString = moment(this.incident._frontData.start_time).format();

            this.toDate = moment(this.incident._frontData.end_time);
            this.lastToDate = moment(this.incident._frontData.end_time);
            this.toDateString = moment(this.incident._frontData.end_time).format();

            this.is.setPickerFromDate(moment(this.incident._frontData.start_time));
            this.is.setPickerToDate(moment(this.incident._frontData.end_time));

            this.dateRange = from + " - " + to;
        } else if (this.incident && this.incident._frontData.start_time && !this.incident._frontData.end_time) {
            const now = moment();

            const from: string = moment(this.incident._frontData.start_time).format(this.dateFormat);
            const to: string = now.format(this.dateFormat);

            this.fromDate = moment(this.incident._frontData.start_time);
            this.lastFromDate = moment(this.incident._frontData.start_time);
            this.fromDateString = moment(this.incident._frontData.start_time).format();

            this.toDate = now;
            this.lastToDate = now;
            this.toDateString = now.format();

            this.is.setPickerFromDate(moment(this.incident._frontData.start_time));
            this.is.setPickerToDate(now);

            this.dateRange = from + " - " + to;
        } else {
            this.setDateRangeFromPreset("LAST_12_HOURS");
        }
    }

    setDateRangeFromChild() {
        const from: string = this.fromDate.format(this.dateFormat);
        const to: string = this.toDate.format(this.dateFormat);
        this.fromDateString = this.fromDate.format();
        this.toDateString = this.toDate.format();
        this.dateRange = from + " - " + to;
        this.hasIncidentChanged();
    }

    toggleDatePicker() {
        this.showDatePicker = true;
        this.hasIncidentChanged();
    }

    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    haveDatesChanged() {
        if (this.incident && this.incident._frontData.start_time && this.incident._frontData.end_time) {
            const savedFrom = moment(this.incident._frontData.start_time);
            const savedTo = moment(this.incident._frontData.end_time);
            if (savedFrom.isSame(this.fromDate) && savedTo.isSame(this.toDate)) {
                this.datesChanged = false;
            } else this.datesChanged = true;
        } else this.datesChanged = false;
    }

    resetDate() {
        if (this.incident && this.incident._frontData.start_time && this.incident._frontData.end_time) {
            const from: string = moment(this.incident._frontData.start_time).format(this.dateFormat);
            const to: string = moment(this.incident._frontData.end_time).format(this.dateFormat);
            //
            this.is.setPickerFromDate(moment(this.incident._frontData.start_time));
            this.is.setPickerToDate(moment(this.incident._frontData.end_time));

            this.dateRange = from + " - " + to;
            //
            this.fromDate = moment(this.incident._frontData.start_time);
            this.toDate = moment(this.incident._frontData.end_time);
            this.lastFromDate = moment(this.incident._frontData.start_time);
            this.lastToDate = moment(this.incident._frontData.end_time);
            this.fromDateString = this.fromDate.format();
            this.toDateString = this.toDate.format();
        }
        this.hasIncidentChanged();
    }

    closeDatePicker() {
        window.focus();
        this.pickerCounter = 0;
        this.showDatePicker = false;
        this.showFrom = true;
        this.hasIncidentChanged();
    }

    clickOutsidePicker() {
        this.pickerCounter = this.pickerCounter + 1;
        if (this.pickerCounter > 1) {
            this.closeDatePicker();
        }
        this.hasIncidentChanged();
    }

    fromDateChanged(event: string) {
        const e: moment.Moment = moment(event);
        if (event !== null) {
            if (this.lastFromDate !== null) {
                if (this.lastFromDate.format() !== e.format()) {
                    this.lastFromDate = e;
                    this.setDateRangeFromPicker();
                }
            } else {
                this.lastFromDate = e;
                this.setDateRangeFromPicker();
            }
        }
    }

    toDateChanged(event: string) {
        const e: moment.Moment = moment(event);
        if (event !== null) {
            if (this.lastToDate !== null) {
                if (this.lastToDate.format() !== e.format()) {
                    this.lastToDate = e;
                    this.setDateRangeFromPicker();
                }
            } else {
                this.lastToDate = e;
                this.setDateRangeFromPicker();
            }
        }
    }

    setFrom(from: moment.Moment) {
        let changed;
        if (this.fromDate) {
            if (from.format() !== this.fromDate.format()) changed = true;
        }
        this.fromDate = from;
        if (changed) this.fromChanged();
    }

    setTo(to: moment.Moment) {
        let changed;
        if (this.toDate) {
            if (to.format() !== this.toDate.format()) changed = true;
        }
        this.toDate = to;
        if (changed) this.toChanged();
    }

    toChanged() {
        this.is.setToDate(this.toDate);
        this.is.setPickerToDate(this.toDate);
    }

    fromChanged() {
        this.is.setFromDate(this.fromDate);
        this.is.setPickerFromDate(this.fromDate);
    }

    // TREE
    /** The selection for checklist */
    checklistSelection = new SelectionModel<flatNode | null>(true /* multiple */);

    private _transformer = (node: ObjectNode, level: number) => {
        if (level === 0) {
            return {
                expandable: !!node.children && node.children.length > 0,
                id: node.id,
                name: node.name,
                type: node.type,
                object: node.object,
                level: level
            };
        } else {
            return {
                expandable: !!node.children && node.children.length > 0,
                level: level,
                name: node.name,
                objectId: node.objectId,
                objectType: node.objectType,
                subType: node.subType,
                panels: node.panels
            };
        }
    };

    treeControl = new FlatTreeControl<flatNode>(
        node => node.level,
        node => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children
        // node => node.object
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    getLevel = (node: flatNode) => node.level;
    hasChild = (_: number, node: flatNode) => node.expandable;

    /** Expand or contract all nodes */
    expandContractAllNodes() {
        if (this.nodesExpanded) this.treeControl.collapseAll();
        else this.treeControl.expandAll();
        this.areNodesExpanded();
    }

    areNodesExpanded() {
        const descendants = this.treeControl.dataNodes;
        let bool = false;
        for (const node of descendants) {
            if (node.expandable) {
                const expanded = this.treeControl.isExpanded(node);
                if (expanded) bool = true;
            }
        }
        this.nodesExpanded = bool;
    }

    toggleButtonClick() {
        this.areNodesExpanded();
    }

    /** Get count of selected nodes */
    selectedNodeCount() {
        const selected = this.checklistSelection.selected;
        let count = 0;
        for (const node of selected) {
            if (node?.level) {
                count++;
            }
        }
        this.selectedCount = count;
    }

    /** Select all nodes of a certain graph and object type */
    selectAllNodesByType(node: flatNode) {
        for (const dataNode of this.treeControl.dataNodes) {
            if (dataNode.level && dataNode.objectType === node.objectType && dataNode.name === node.name) {
                if (!this.maxGraphsReached(dataNode)) {
                    this.checklistSelection.select(dataNode);
                    this.checkAllParentsSelection(dataNode);
                }
            }
        }
        this.treeSelectionChanged();
    }

    clearAllNodesByType(node: flatNode) {
        for (const dataNode of this.treeControl.dataNodes) {
            if (dataNode.level && dataNode.objectType === node.objectType && dataNode.name === node.name) {
                this.checklistSelection.deselect(dataNode);
                this.checkAllParentsSelection(dataNode);
            }
        }
        this.treeSelectionChanged();
    }

    selectOnlyNode(node: flatNode) {
        for (const dataNode of this.treeControl.dataNodes) {
            if (dataNode.level) {
                this.checklistSelection.deselect(dataNode);
            }
        }
        if (node) {
            this.checklistSelection.select(node);
            this.checkAllParentsSelection(node);
        }
        this.treeSelectionChanged();
    }

    /** Whether all the nodes are selected. */
    allSelected() {
        let bool = true;
        for (const node of this.treeControl.dataNodes) {
            if (!this.descendantsAllSelected(node) && node.expandable) bool = false;
        }
        return bool;
    }

    allPartiallySelected() {
        const descendants = this.treeControl.dataNodes;
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.allSelected();
    }

    allItemSelectionToggle() {
        const descendants = this.treeControl.dataNodes;

        descendants.forEach(child => {
            (this.treeHeaderCheck && !child.level) ||
            (this.treeHeaderCheck && child.level && !this.maxGraphsReached(child))
                ? this.checklistSelection.select(child)
                : this.checklistSelection.deselect(child);
        });

        this.treeSelectionChanged();
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: flatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every(child => {
                return this.checklistSelection.isSelected(child);
            });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: flatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    itemSelectionToggle(node: flatNode): void {
        if (node) this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);

        // Force update for the parent
        descendants.forEach(child => {
            this.checklistSelection.isSelected(node) && !this.maxGraphsReached(child)
                ? this.checklistSelection.select(child)
                : this.checklistSelection.deselect(child);

            if (!this.maxGraphsReached(child)) this.checklistSelection.isSelected(child);
        });

        if (node) this.checkAllParentsSelection(node);
        this.treeSelectionChanged();
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    leafItemSelectionToggle(node: flatNode): void {
        if (node) this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
        this.treeSelectionChanged();
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: flatNode): void {
        let parent: flatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: flatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every(child => {
                return this.checklistSelection.isSelected(child);
            });
        if (node)
            if (nodeSelected && !descAllSelected) {
                this.checklistSelection.deselect(node);
            } else if (!nodeSelected && descAllSelected) {
                this.checklistSelection.select(node);
            }
    }

    /* Get the parent node of a node */
    getParentNode(node: flatNode): flatNode | null {
        if (!node) {
            return null;
        }

        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    treeSelectionChanged() {
        this.graphPanelConfig = [];
        for (const item of this.checklistSelection.selected) {
            if (item?.level === 1) {
                for (const panel of item.panels) {
                    const exists = this.graphPanelConfig.find(g => g.panel === panel);
                    if (exists) {
                        exists.objectIds.push(item.objectId);
                    } else {
                        this.graphPanelConfig.push({
                            objectIds: [item.objectId],
                            objectType: item.objectType,
                            panel: panel
                        });
                    }
                }
            }
        }

        this.reloadGraphs();
        this.selectedNodeCount();
        this.hasIncidentChanged();
    }

    maxGraphsReached(node) {
        if (this.checklistSelection.selected && this.checklistSelection.selected.length) {
            const arr = this.checklistSelection.selected.filter(
                n => n.level && n.name === node.name && n.objectType === node.objectType
            );
            const found = this.checklistSelection.selected.find(
                n => n.level && n.name === node.name && n.objectType === node.objectType && n.objectId === node.objectId
            );
            if (arr.length > 9 && !found) return true;
        }
        return false;
    }

    reloadGraphs() {
        this.loadingGraphs = true;
        //
        const panelConfig: FlexDashboardEncoder.FlexDashboardConfig = {
            graph: "incidents",
            graphId: this.incidentID,
            columns: parseInt(this.graphColumns, 10),
            duration: "7d",
            legend: this.legend,
            annotations: this.canEditIncident(),
            dark: this.darkMode,
            panels: this.graphPanelConfig
        };
        //
        if (this.graphPanelConfig && this.graphPanelConfig.length > 0) {
            this.graphLink = this.fgs.custom(panelConfig);
        } else this.graphLink = "";
        //
        this.loadingGraphs = false;
    }

    // flex graphs
    getGraphOptions(type: string, object, tlo: boolean) {
        if (type === "network") {
            const config = tlo
                ? [
                      {
                          name: "incident_objects",
                          objectId: this.incidentID,
                          objectType: "incidents",
                          panels: ["incidents-objects"]
                      }
                  ]
                : [];
            return config;
        } else if (type === "zec") {
            const config = [
                {
                    name: "cpu",
                    objectId: object.id,
                    objectType: type,
                    panels: ["zec-system-cpu"]
                },
                {
                    name: "ram",
                    objectId: object.id,
                    objectType: type,
                    panels: ["zec-system-memory"]
                }
            ];
            return config;
        } else if (type === "feeder") {
            const config = [
                {
                    name: "cpu",
                    objectId: object.id,
                    objectType: type,
                    panels: ["feeder-system-cpu"]
                },
                {
                    name: "ram",
                    objectId: object.id,
                    objectType: type,
                    panels: ["feeder-system-memory"]
                }
            ];
            return config;
        } else if (type === "receiver") {
            const config = [
                {
                    name: "cpu",
                    objectId: object.id,
                    objectType: type,
                    panels: ["receiver-system-cpu"]
                }
            ];
            return config;
        } else if (type === "broadcaster") {
            const config = [
                {
                    name: "hdd",
                    objectId: object.id,
                    objectType: type,
                    panels: ["broadcaster-system-sys-hdd", "broadcaster-system-vod-hdd"]
                },
                {
                    name: "cpu",
                    objectId: object.id,
                    objectType: type,
                    panels: ["broadcaster-system-cpu"]
                },
                {
                    name: "ram",
                    objectId: object.id,
                    objectType: type,
                    panels: ["broadcaster-system-memory"]
                },
                {
                    name: "gpu",
                    objectId: object.id,
                    objectType: type,
                    panels: [
                        "broadcaster-system-gpu",
                        "broadcaster-system-gpu-memory",
                        "broadcaster-system-gpu-encoder",
                        "broadcaster-system-gpu-decoder"
                    ]
                },
                {
                    name: "send/receive_bitrate",
                    objectId: object.id,
                    objectType: type,
                    panels: ["broadcaster-network-send-bitrate", "broadcaster-network-receive-bitrate"]
                },
                {
                    name: "udp/errors",
                    objectId: object.id,
                    objectType: type,
                    panels: ["broadcaster-udp-send-errors", "broadcaster-udp-receive-errors"]
                }
            ];
            if (tlo)
                config.unshift({
                    name: "incident_objects",
                    objectId: this.incidentID,
                    objectType: "incidents",
                    panels: ["incidents-objects"]
                });
            return config;
        } else if (type === "source") {
            const config = [
                {
                    name: "packet_loss",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-packet-loss"]
                },
                {
                    name: "not_recovered",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-not-recovered"]
                },
                {
                    name: "rtt_min/max",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-rtt-max", "source-network-rtt-min"]
                },
                {
                    name: "jitter_min/max",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-jitter-max", "source-network-jitter-min"]
                },
                {
                    name: "network_bitrate",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-bitrate-network"]
                },
                {
                    name: "bitrate_min/max",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-network-bitrate-max", "source-network-bitrate-min"]
                },
                {
                    name: "cc_errors",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-cqa-cc-errors"]
                },
                {
                    name: "reconnections",
                    objectId: object.id,
                    objectType: type,
                    panels: ["source-cqa-reconnections"]
                }
            ];
            return config;
        } else if (type === "channel") return [];
        else if (type === "target") {
            if (object.apiType === TargetApiType.Rist) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "rist",
                        panels: ["rist-target-network-bitrate"]
                    },
                    {
                        name: "not_recovered",
                        objectId: object.objId,
                        objectType: "rist",
                        panels: ["rist-target-network-not-recovered"]
                    }
                ];
                return config;
            } else if (object.apiType === TargetApiType.Rtmp) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "rtmp_push",
                        panels: ["rtmp_push-target-network-bitrate"]
                    },
                    {
                        name: "send_errors",
                        objectId: object.objId,
                        objectType: "rtmp_push",
                        panels: ["rtmp_push-target-network-send-errors"]
                    }
                ];
                return config;
            } else if (object.apiType === TargetApiType.Srt) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "srt_targets",
                        panels: ["srt_targets-target-network-bitrate"]
                    },
                    {
                        name: "not_recovered",
                        objectId: object.objId,
                        objectType: "srt_targets",
                        panels: ["srt_targets-target-network-not-recovered"]
                    }
                ];
                return config;
            } else if (object.apiType === TargetApiType.UdpRtp) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "udp_rtp",
                        panels: ["udp_rtp-target-network-bitrate"]
                    },
                    {
                        name: "send_errors",
                        objectId: object.objId,
                        objectType: "udp_rtp",
                        panels: ["udp_rtp-target-network-send-errors"]
                    }
                ];
                return config;
            } else if (object.apiType === TargetApiType.Pull) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-bitrate"]
                    },
                    {
                        name: "rtt",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        subType: TargetApiType.Pull,
                        panels: ["zixi_pull-target-network-rtt"]
                    },
                    {
                        name: "jitter",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-network-jitter"]
                    },
                    {
                        name: "recovered",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-network-recovered"]
                    },
                    {
                        name: "not_recovered",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-network-not-recovered"]
                    },
                    {
                        name: "reconnections",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-network-reconnections"]
                    },
                    {
                        name: "send_errors",
                        objectId: object.objId,
                        objectType: "zixi_pull",
                        panels: ["zixi_pull-target-network-send-errors"]
                    }
                ];
                return config;
            } else if (object.apiType === TargetApiType.Push) {
                const config = [
                    {
                        name: "bitrate",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-bitrate"]
                    },
                    {
                        name: "rtt",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-rtt"]
                    },
                    {
                        name: "jitter",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-jitter"]
                    },
                    {
                        name: "recovered",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-recovered"]
                    },
                    {
                        name: "not_recovered",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-not-recovered"]
                    },
                    {
                        name: "reconnections",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-reconnections"]
                    },
                    {
                        name: "send_errors",
                        objectId: object.objId,
                        objectType: "zixi_push",
                        panels: ["zixi_push-target-network-send-errors"]
                    }
                ];
                return config;
            } else return [];
        } else if (type === "mediaconnect_sources") return [];
        else return [];
    }
}
